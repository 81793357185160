import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static values = { active: Boolean }

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

}
